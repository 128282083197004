export const CARD_MIN_HEIGHT = '136px'
// keep in mind longest date text 'Wednesday 30th September - 302px'
export const CARD_MOBILE_SINGLE_WIDTH = '288px'
export const CARD_MOBILE_MULTIPLE_WIDTH = '250px'
export const CARD_DESKTOP_SINGLE_WIDTH = '500px'
export const CARD_DESKTOP_MULTIPLE_WIDTH = '302px'
export const REMINDER_CARD_MIN_HEIGHT = '136px'
export const REMINDER_CARD_CONTAINER_WIDTH = '300px'
export const SINGLE_REMINDER_CARD_CONTAINER_WIDTH = '100%'
export const TRACKING_EVENTS_LABEL =
  'reminders | upcoming occasions | carousel | 1/2' // hardcoded carousel position is sus

// upcoming occasion mvt
export const CARD_VARIANT_MIN_HEIGHT = '240px'
export const CARD_VARIANT_WIDTH = '280px'
