import { EventObject, trackGAEvent } from '@moonpig/web-core-analytics'
import {
  errorGAEvent,
  impressionGAEvent,
  selectContentGAEvent,
} from '../../../analytics/commonGAEvents'

export enum ComponentType {
  Modal = 'modal',
  Banner = 'banner',
}

const category = 'account'

const getContext = (isModal: boolean) => {
  return isModal ? ComponentType.Modal : ComponentType.Banner
}

export const privacyNoticeClickEventData = ({
  isModal,
}: {
  isModal: boolean
}): EventObject => {
  const component: ComponentType = getContext(isModal)
  return {
    event: 'select_content',
    content_data: {
      content_type: `account | view email sign up promotion privacy information | ${component} | privacy information`,
    },
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,

    event_data: {
      action: 'view email sign up promotion privacy information',
      category,
      label: `${component} | privacy information`,
      non_interaction: true,
      value: undefined,
    },
  }
}

export const track = trackGAEvent

export const initialImpression = ({ isModal }: { isModal: boolean }) =>
  impressionGAEvent({
    category,
    type: 'sign up and get 10% off',
    action: 'view email sign up promotion',
    context: getContext(isModal),
  })

export const modalDismissSelectContent = ({
  showThankYou,
}: {
  showThankYou: boolean
}) =>
  selectContentGAEvent({
    category,
    type: 'dismiss',
    action: showThankYou
      ? 'close email sign up promotion thank you'
      : 'close email sign up promotion',
    context: ComponentType.Modal,
  })

export const successImpression = ({ isModal }: { isModal: boolean }) =>
  impressionGAEvent({
    category,
    type: 'sign up and get 10% off',
    action: 'sign up promotion email subscribe success',
    context: getContext(isModal),
  })

export const selectContent = ({ isModal }: { isModal: boolean }) =>
  selectContentGAEvent({
    category,
    type: 'get offer',
    action: 'subscribe to sign up promotion email',
    context: getContext(isModal),
  })

export const genericError = ({
  isModal,
  errorReason,
}: {
  isModal: boolean
  errorReason: string
}) =>
  errorGAEvent({
    category,
    type: 'sign up and get 10% off',
    action: 'sign up promotion email subscribe error',
    context: getContext(isModal),
    errorCode: 501,
    errorReason: errorReason.toLocaleLowerCase(),
  })
