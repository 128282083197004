import {
  ImpressionEvent,
  SelectContentEvent,
} from '@moonpig/web-core-analytics'

export const upcomingOccasionsV2LoadEvent: ImpressionEvent = {
  event: 'impression',
  content_data: {
    content_type: 'reminders | shop for reminder load | upcoming occasions v2',
  },
  event_data: {
    action: 'shop for reminder load',
    category: 'reminders',
    label: 'upcoming occasions v2',
    non_interaction: true,
    value: 1,
  },
}

export const upcomingOccasionsV2ImpressionEvent: ImpressionEvent = {
  event: 'impression',
  content_data: {
    content_type: 'reminders | shop for reminder | upcoming occasions v2',
  },
  event_data: {
    action: 'shop for reminder',
    category: 'reminders',
    label: 'upcoming occasions v2',
    non_interaction: true,
    value: 1,
  },
}

export const upcomingOccasionV2ShopForReminderEvent: SelectContentEvent = {
  event: 'select_content',
  event_data: {
    action: 'view all reminders',
    category: 'reminders',
    label: 'shop for reminder | upcoming occasions v2',
    non_interaction: true,
    value: 1,
  },
}
