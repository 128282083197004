import { ApplyDiscountVoucher } from '@moonpig/web-shared-promotions'
import { Page, PageContext, PageRedirect } from '@moonpig/web-core-app'
import Head from 'next/head'
import React, { ComponentProps } from 'react'
import { Routes, router } from '@moonpig/web-core-routing'
import { Region } from '@moonpig/web-core-types'
import { useLanguage } from '@moonpig/web-core-stores'
import { ConsoleLogger } from '@moonpig/common-logging-console'
import { PageLayout } from '@moonpig/web-explore-types-graphql'
import { BasketProvider } from '@moonpig/web-shared-products'
import { ContentModule, PageMeta } from '../../types'
import { GalleryPage } from '../GalleryPage'
import { InfoPage } from '../InfoPage'
import { BlogHomepage } from '../BlogHomepage'
import { BlogCategoryPage } from '../BlogCategoryPage'
import { BlogPostPage } from '../BlogPostPage'
import { LandingPage } from '../LandingPage'
import { HomePage } from '../HomePage'
import { AlertProvider } from '../../contexts/alert/Provider'
import { dataInferenceQuery, pageQuery } from './query'
import { PageQuery } from './__generated__/query'

export type PageQuery_page_Page_modules = Extract<
  PageQuery['page'],
  { __typename: 'Page' }
>['modules'][0]

type Content =
  | {
      layout: 'Landing'
      props: ComponentProps<typeof LandingPage>
    }
  | {
      layout: 'Gallery'
      props: ComponentProps<typeof GalleryPage>
    }
  | {
      layout: 'Info'
      props: ComponentProps<typeof InfoPage>
    }
  | {
      layout: 'BlogHomepage'
      props: ComponentProps<typeof BlogHomepage>
    }
  | {
      layout: 'BlogCategoryPage'
      props: ComponentProps<typeof BlogCategoryPage>
    }
  | {
      layout: 'BlogPost'
      props: ComponentProps<typeof BlogPostPage>
    }
  | {
      layout: 'Homepage'
      props: ComponentProps<typeof HomePage>
    }

const renderContent = (content: Content) => {
  switch (content.layout) {
    case 'Landing':
      return <LandingPage {...content.props} />
    case 'Gallery':
      return <GalleryPage {...content.props} />
    case 'Info':
      return <InfoPage {...content.props} />
    case 'BlogHomepage':
      return <BlogHomepage {...content.props} />
    case 'BlogCategoryPage':
      return <BlogCategoryPage {...content.props} />
    case 'BlogPost':
      return <BlogPostPage {...content.props} />
    case 'Homepage':
      return <HomePage {...content.props} />
    /* istanbul ignore next */
    default:
      return null
  }
}

type Props = {
  meta: PageMeta
  content: Content
  region: Region
  success?: string
  uqd?: string
  hostname?: string
}

export const ContentPage: Page<Props, Routes['content']> = ({
  meta,
  content,
  uqd,
  hostname,
}) => {
  const language = useLanguage()
  const shouldRenderWithMeta = content.layout === 'Gallery'

  return (
    <>
      <ApplyDiscountVoucher uqd={uqd} />
      <Head>
        <title>{meta.title}</title>
        <link
          rel="canonical"
          key="content-head-canonical"
          data-testid="content-head-canonical"
          href={`${hostname}${meta.canonicalSubPath}`}
        />
        <meta name="title" content={meta.title} />
        <meta name="description" content={meta.description} />
        <meta
          property="og:url"
          content={`${hostname}${meta.canonicalSubPath}`}
        />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:locale" content={language.replace('-', '_')} />
        <meta
          data-testid="robots"
          name="robots"
          content={`${meta.noIndex ? 'noindex' : 'index'}, follow`}
        />
        {meta.structuredData.map(({ json }, index) => (
          <script
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: json }}
          />
        ))}
      </Head>
      <BasketProvider>
        <AlertProvider>
          {renderContent(
            shouldRenderWithMeta
              ? { ...content, props: { ...content.props, meta } }
              : content,
          )}
        </AlertProvider>
      </BasketProvider>
    </>
  )
}

/* istanbul ignore next */
const createPath = (path: string) => (path.endsWith('/') ? path : `${path}/`)

const createPageContentModule = (
  module: PageQuery_page_Page_modules,
): ContentModule | null => {
  switch (module.__typename) {
    case 'ModuleHeading':
      return { type: module.__typename, ...module }
    case 'ModuleBreadcrumbs':
      return { type: module.__typename, ...module }
    case 'ModuleInfo':
      return { type: module.__typename, ...module }
    case 'ModuleTiles':
      return { type: module.__typename, ...module }
    case 'ModuleDescription':
      return { type: module.__typename, ...module }
    case 'ModuleSearchResults':
      return {
        type: module.__typename,
        ...module,
        promotionId:
          module.promotionId === null ? undefined : module.promotionId,
        facetDetails: module.facetDetails.map(f => ({
          ...f,
          group: f.group || 'default',
        })),
      }
    case 'ModulePromoTile':
      return { type: module.__typename, ...module }
    case 'ModuleTabbedProductLists':
      return { type: module.__typename, ...module }
    case 'ModuleMissionButtons':
      return { type: module.__typename, ...module }
    case 'ModuleRecommendationsForCustomer':
      return { type: module.__typename, ...module }
    case 'ModulePlaceholder':
      return { type: module.__typename, ...module }
    case 'ModuleDeliveryStrip':
      return { type: module.__typename, ...module }
    case 'ModuleCTAStrip':
      return { type: module.__typename, ...module }
    case 'ModuleHero':
      return { type: module.__typename, ...module }
    case 'ModuleDynamicHero':
      return {
        type: module.__typename,
        ...module,
        ctas: module.ctas
          ? module.ctas
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            /* istanbul ignore next */ ([] as any),
      }

    case 'ModuleOfferStrip':
      return { type: module.__typename, ...module }
    case 'ModulePlacards':
      return { type: module.__typename, ...module }
    case 'ModuleSEO':
      return { type: module.__typename, ...module }
    case 'ModuleVideo':
      return { type: module.__typename, ...module }
    case 'ModuleRichTextImage':
      return { type: module.__typename, ...module }
    case 'ModuleUspCarousel':
      return { type: module.__typename, ...module }
    case 'ModuleCard':
      return { type: module.__typename, ...module }
    case 'ModuleRichText':
      return {
        type: module.__typename,
        ...module,
        blocks: module.blocks.map(block => {
          switch (block.__typename) {
            case 'RichTextBlockHTML':
              return { type: block.__typename, ...block }
            default: {
              return {
                type: block.__typename,
                ...block,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any
                module: createPageContentModule(block.module as any)!,
              }
            }
          }
        }),
      }
    case 'ModuleBlogPostDate':
      return { type: module.__typename, ...module }
    case 'ModuleBlogPostAuthor':
      return { type: module.__typename, ...module }
    case 'ModuleBlogPostCategory':
      return { type: module.__typename, ...module }
    case 'ModuleBlogPostsListing':
      return { type: module.__typename, ...module }
    case 'ModuleBlogHomepageImage':
      return { type: module.__typename, ...module }
    case 'ModuleBlogPostsCategories':
      return { type: module.__typename, ...module }
    case 'ModuleTwoColumnPlacard': {
      const { body: unsafeBody, ...moduleProps } = module
      return { type: module.__typename, unsafeBody, ...moduleProps }
    }
    case 'ModuleEmailCapture':
      return { type: module.__typename, ...module }
    default:
      return null
  }
}

ContentPage.cacheMaxAgeSeconds = 60 * 2 // 2 mins

ContentPage.layout = 'default'
ContentPage.shouldRenderAppBanner = true

const pdpRedirectUrl = (
  url: string,
  urlQuery: PageContext<Routes['content']>['query'],
  logger: ConsoleLogger,
): { pageRedirect: PageRedirect } | null => {
  const pdpRegex =
    /\/?(.{2,3})\/(personalised-cards|personalised-mugs|flowers-and-plants|gifts|wenskaarten|cadeaus|bloemen-en-planten)\/([^/]+)\/((?!\.)[a-zA-Z]+(?:(?:[0-9]+[a-zA-Z0-9\-_]*)|(?:(?:-|_)[a-zA-Z]{1,2})))\/?(\?.*)?$/i

  const match = url.match(pdpRegex)

  if (match) {
    const [, , departmentSlug, slug, id] = match

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { region, parts, ...query } = urlQuery

    const { as } = router.build('product-details', {
      region,
      departmentSlug,
      slug,
      id,
      ...query,
    })

    logger.info(
      'A match has been found in the method {method}, redirecting to {location}',
      {
        method: 'pdpRedirectUrl',
        location: as,
      },
    )

    return {
      pageRedirect: {
        statusCode: 301,
        location: as,
      },
    }
  }

  return null
}

export type ContentData = {
  modules: ContentModule[]
}

const propsFromPage = (
  pageLayout: PageLayout,
):
  | ['Landing', typeof LandingPage.getInitialProps]
  | ['Homepage', typeof HomePage.getInitialProps]
  | ['Gallery', typeof GalleryPage.getInitialProps]
  | ['Info', typeof InfoPage.getInitialProps]
  | ['BlogHomepage', typeof BlogHomepage.getInitialProps]
  | ['BlogCategoryPage', typeof BlogCategoryPage.getInitialProps]
  | ['BlogPost', typeof BlogPostPage.getInitialProps]
  | ['none', null] => {
  switch (pageLayout) {
    case 'Landing':
      return [pageLayout, LandingPage.getInitialProps]
    case 'Homepage':
      return [pageLayout, HomePage.getInitialProps]
    case 'Gallery':
      return [pageLayout, GalleryPage.getInitialProps]
    case 'Info':
      return [pageLayout, InfoPage.getInitialProps]
    case 'BlogHomepage':
      return [pageLayout, BlogHomepage.getInitialProps]
    case 'BlogCategoryPage':
      return [pageLayout, BlogCategoryPage.getInitialProps]
    case 'BlogPost':
      return [pageLayout, BlogPostPage.getInitialProps]
    /* istanbul ignore next */
    default:
      return ['none', null]
  }
}

ContentPage.getInitialProps = async context => {
  const {
    graphQL,
    preview,
    query,
    region,
    environment,
    logger,
    fetch,
    authEnabled,
    metrics,
    platform,
    flags,
    getExperimentString,
  } = context

  const { success } = query
  const pdpRedirect = pdpRedirectUrl(context.path, query, logger)
  if (pdpRedirect) {
    return pdpRedirect
  }

  const path = createPath(context.path)

  const dynamicHeroExperimentEnabled =
    getExperimentString('dynamic-hero', {
      fallback: 'control',
    }) === 'variant'

  const homePageDynamicHeroModel = getExperimentString(
    'homepage-dynamic-hero-model',
    {
      fallback: 'disabled',
    },
  )

  const [{ page, redirect }, dynamicHeroData] = await metrics.traceAsync(
    {
      traceName: 'content-page',
      metricName: 'content-page',
    },
    () =>
      Promise.all([
        pageQuery(graphQL, { path, preview }),
        dataInferenceQuery(fetch, {
          flags,
          path,
          isMobile: platform.isMobile,
          metrics,
          authEnabled,
          experiments: {
            dynamicHeroExperimentEnabled,
            homePageDynamicHeroModel,
          },
        }),
      ]),
  )

  if (redirect) {
    return {
      pageRedirect: {
        statusCode: 301,
        location: redirect.url,
      },
    }
  }

  if (!page) {
    return {
      pageError: {
        statusCode: 404,
      },
    }
  }

  const { layout, modules, ...meta } = page

  const [pageLayout, initialPropsPromise] = propsFromPage(layout)

  const initialProps = await initialPropsPromise?.({
    ...context,
    modules: modules
      .map(createPageContentModule)
      .filter(Boolean) as ContentModule[],
    dynamicHeroData,
  })

  /* istanbul ignore next */
  if (!initialProps) {
    return {
      pageError: {
        statusCode: 500,
      },
    }
  }

  /* istanbul ignore next */
  if (initialProps.pageError || initialProps.pageRedirect) {
    return initialProps
  }

  const { pageOptions, props } = initialProps
  return {
    pageOptions,
    meta,
    content: {
      layout: pageLayout,
      props,
    } as Content,
    region,
    success,
    uqd: query.uqd,
    hostname: environment.hostUrl,
  }
}
