import React, { useEffect } from 'react'

import styled from 'styled-components'
import { system as s } from '@moonpig/launchpad-system'
import { RichTextContainer } from '@moonpig/launchpad-components'

const CookiePolicy = styled.div`
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h5,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h6,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy li,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
    ${s({ color: 'colorTextBody', lineHeight: '24px', fontSize: '1rem' })}
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description {
    font-weight: 300;
  }

  #onetrust-banner-sdk .ot-sdk-container,
  #onetrust-pc-sdk .ot-sdk-container,
  #ot-sdk-cookie-policy .ot-sdk-container {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`

export const CookieListModule = () => {
  useEffect(() => {
    if (window.OneTrust && window.OneTrust.initializeCookiePolicyHtml) {
      window.OneTrust.initializeCookiePolicyHtml()
    }
  }, [])

  return (
    <RichTextContainer>
      <CookiePolicy id="ot-sdk-cookie-policy" />
    </RichTextContainer>
  )
}
