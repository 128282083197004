import React, { FC, useEffect } from 'react'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { useInView } from 'react-intersection-observer'
import {
  createViewItemListGAEvent,
  useFavouritesState,
  useHandleFavourite,
} from '@moonpig/web-shared-products'
import { Carousel } from '@moonpig/web-shared-components'
import { ContinueJourneyProduct } from '../../types'
import { useFindLocaleText } from '../../text-localisation'
import { useRecentlyViewedProducts } from '../../utils/useRecentlyViewedProducts'
import { ModuleHeader } from '../../components/ModuleContainer'
import { useDraftProducts, useIsMobile } from '../../utils'
import { ProductCard } from './ProductCard'
import { ModuleTracking } from '../types'

export type ContinueCreationModuleProps = ModuleTracking

export const ContinueJourney: FC<ContinueCreationModuleProps> = ({
  tracking,
}) => {
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const localiseText = useFindLocaleText()
  const title = localiseText('find.pick_up_where_you_left_off')
  const myDraftText = localiseText('find.my_draft')
  const recentlyViewedText = localiseText('find.recently_viewed')

  const [productListRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const recentlyViewedProducts = useRecentlyViewedProducts()

  const draftProducts = useDraftProducts()

  const draftProduct = draftProducts[0]

  const recentlyViewedProductsWithoutDraft = recentlyViewedProducts.filter(
    ({ id }) => id !== draftProduct?.id,
  )

  const productsToDisplay: ContinueJourneyProduct[] = [
    draftProduct,
    ...recentlyViewedProductsWithoutDraft,
  ].filter(product => !!product)

  const { favourites } = useFavouritesState()
  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: recentlyViewedProductsWithoutDraft.length,
      pageLocation: tracking.pageLocation,
    },
    removeWithConfirmation: false,
  })

  const createProcessedViewItemListEvent = (
    products: ContinueJourneyProduct[],
  ) => {
    const { pageLocation, moduleProductListIndex, moduleProductListsLength } =
      tracking
    const modulesCount = `${moduleProductListIndex}/${moduleProductListsLength}`
    const label = `${pageLocation} | ${title} | carousel | ${modulesCount}`

    const gaEvent = createViewItemListGAEvent({
      products,
      label,
    })

    return {
      ...gaEvent,
      ecommerce: {
        ...gaEvent.ecommerce,
        items: gaEvent.ecommerce.items.map((item, index) => ({
          ...item,
          item_list_name: products[index].isDraftProduct
            ? `${item.item_list_name} | ${myDraftText.toLowerCase()}`
            : `${item.item_list_name} | ${recentlyViewedText.toLowerCase()}`,
        })),
      },
    }
  }

  useEffect(() => {
    if (inView) {
      const processedViewItemListEvent =
        createProcessedViewItemListEvent(productsToDisplay)

      trackGAEventOnce(processedViewItemListEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentlyViewedProducts, inView])

  const isMobile = useIsMobile()

  const firstRecommendationIndex = productsToDisplay.findIndex(
    product => !product.isDraftProduct,
  )

  return productsToDisplay.length > 0 ? (
    <div data-testid="module-continue-journey">
      <ModuleHeader title={title} />
      <div ref={productListRef} data-testid="web-browse-product-list-component">
        <Carousel hideScrollButtons={isMobile}>
          {productsToDisplay.map((product, index) => {
            const showPill =
              product.isDraftProduct || index === firstRecommendationIndex
            const label = product.isDraftProduct
              ? myDraftText
              : recentlyViewedText

            return (
              <ProductCard
                key={product.id}
                product={product}
                tracking={{
                  pageLocation: tracking.pageLocation,
                  carouselLocation: index + 1,
                  totalItems: productsToDisplay.length,
                  componentName: 'continue journey carousel',
                  productIndex: index,
                }}
                showPill={showPill}
                label={label}
                isFavourited={Boolean(
                  favourites.find(
                    /* istanbul ignore next */ p => p?.id === product.id,
                  ),
                )}
                handleFavourite={handleFavourite}
              />
            )
          })}
        </Carousel>
      </div>
    </div>
  ) : null
}
