import { useStoreId } from '@moonpig/web-core-stores'
import { useRouter } from '@moonpig/web-core-routing'
import { MembershipType } from '@moonpig/web-explore-types-graphql'
import { useAlertDispatcher } from '../../../contexts/alert/Context'
import { useAddMembershipToBasketService } from './useAddMembershipToBasketService'
import { useFindLocaleText } from '../../../text-localisation'
import { trackAddMembershipToCart, trackStartShopping } from './analytics'

type MapAddMembershipToBasketActionToCTAInput = {
  title: string
  membership: MembershipType
}

type MapAddMembershipToBasketActionToCTAResult = {
  title: string
  url?: string
  onClick?: () => void
}

type UseAddMembershipToBasketActionInput = {
  /* EMPTY */
}

type UseAddMembershipToBasketAction = (
  input: UseAddMembershipToBasketActionInput,
) => {
  mapAddMembershipToBasketActionToCTA: (
    input: MapAddMembershipToBasketActionToCTAInput,
  ) => MapAddMembershipToBasketActionToCTAResult | undefined
}

export const useAddMembershipToBasketAction: UseAddMembershipToBasketAction =
  () => {
    const store = useStoreId()
    const alertDispatch = useAlertDispatcher()
    const router = useRouter()
    const locale = useFindLocaleText()
    const { getMembership, addMembershipToBasket } =
      useAddMembershipToBasketService()

    const mapAddMembershipToBasketActionToCTA = ({
      title,
      membership,
    }: MapAddMembershipToBasketActionToCTAInput) => {
      if (
        !getMembership.loading &&
        !getMembership.data &&
        !getMembership.called
      ) {
        getMembership.getMembershipState()
        return undefined
      }

      if (getMembership.loading) {
        return undefined
      }

      if (
        getMembership.data?.me.basket?.membership.selected ||
        getMembership.data?.me.customer?.membership
      ) {
        return {
          title: locale('find.start_shopping'),
          onClick: () => {
            trackStartShopping()
            router.push({
              name: 'search',
              params: { region: store },
            })
          },
        }
      }

      return {
        title,
        onClick: () => {
          trackAddMembershipToCart(
            getMembership.data?.me.basket?.id || 'na',
            getMembership.data?.me.basket?.totalItems || 0,
          )
          addMembershipToBasket
            .addMembershipToBasket({
              variables: { input: { membershipType: membership } },
            })
            .then(() => {
              alertDispatch.openAlert({
                variant: 'success',
                message: locale('find.moonpig_plus.added_to_basket'),
              })
              getMembership.refetch()
            })
        },
      }
    }

    return {
      mapAddMembershipToBasketActionToCTA,
    }
  }
