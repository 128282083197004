import { Reducer } from 'react'
import {
  EmailCaptureError,
  EmailRegistrationFailed,
  EmailRegistrationSucceeded,
} from './actions'

type State = { showThankYou: boolean; error?: EmailCaptureError }

export const initialState: State = { showThankYou: false }

export const reducer: Reducer<
  State,
  EmailRegistrationFailed | EmailRegistrationSucceeded
> = (_, action) => {
  switch (action.type) {
    case 'EMAIL_REGISTERED_FAILED':
      return { error: action.payload.errorCode, showThankYou: false }

    case 'EMAIL_REGISTERED_SUCCEEDED':
      return { showThankYou: true }
  }
}
