import React, { FC } from 'react'
import { Box, Flex, SecondaryButton } from '@moonpig/launchpad-components'
import {
  AddedToBasketEvent,
  FavouriteProduct,
  ProductTile,
  ProductTileProduct,
} from '@moonpig/web-shared-products'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { useStoreId } from '@moonpig/web-core-stores'
import { Carousel } from '@moonpig/web-shared-components'
import { ProductListProducts } from '../../queries/ProductList'

type Props = {
  productList: ProductListProducts
  title: string
  url: string
  buttonText: string
  backgroundColor?: string
  favourites: FavouriteProduct[]
  onAddToBasket?: (e: AddedToBasketEvent) => void
  onFavourite: (
    product: ProductTileProduct,
    isFavouriteSelected: boolean,
    index: number,
  ) => Promise<{ removeWithConfirmation: boolean }>
  onButtonClick?: () => void
  onTrackingInfo: (e: { product: ProductTileProduct }) => {
    label: string
    listName: string
  }
}

export const ProductCarousel: FC<Props> = ({
  productList,
  url,
  favourites,
  buttonText,
  onAddToBasket,
  onFavourite,
  onButtonClick,
  onTrackingInfo,
}) => {
  const storeId = useStoreId()
  const emptyTrackingData = {
    pageType: '',
    region: storeId,
    tile: { totalNumberOfProducts: 0, productIndex: 0 },
  }

  return (
    <>
      <Carousel>
        {productList.map((product, index) => {
          const isCardProduct =
            product.category &&
            product.category.department === DepartmentsEnum.GREETING_CARDS
          return (
            <Box
              key={product.id}
              py={{ xs: 6, md: 7 }}
              pr={{ xs: index === productList.length - 1 ? 6 : 4, md: 5 }}
              pl={{ xs: index === 0 ? 6 : 4, md: 5 }}
              height="100%"
            >
              <Box
                width={{
                  xs: `${isCardProduct ? '36' : '60'}vw`,
                  md: '248px',
                }}
                height="100%"
              >
                <ProductTile
                  product={product}
                  onClick={() => {}}
                  onAddToBasket={e => onAddToBasket?.(e)}
                  trackingData={emptyTrackingData}
                  handleFavourite={onFavourite}
                  isFavourited={Boolean(
                    favourites.find(favourite => favourite.id === product.id),
                  )}
                  onTrackingInfo={() => onTrackingInfo({ product })}
                />
              </Box>
            </Box>
          )
        })}
      </Carousel>
      <Flex justifyContent="space-around" pt={5}>
        <SecondaryButton
          href={url}
          width="100%"
          maxWidth="192px"
          onClick={onButtonClick}
        >
          {buttonText}
        </SecondaryButton>
      </Flex>
    </>
  )
}
