import React, { FC } from 'react'
import {
  Flex,
  Heading,
  Text,
  PrimaryButton,
} from '@moonpig/launchpad-components'
import { EmailCaptureContainer, HeadingImage } from './Shared'
import { ModuleEmailCaptureFragment } from '../__generated__/fragment'

type Props = {
  heading: string
  text: string
  showContinueShopping?: boolean
  onContinueShoppingClicked?: () => void
  thankYouButtonText?: string
  image: ModuleEmailCaptureFragment['image']
}

export const ThankYou: FC<Props> = ({
  showContinueShopping,
  heading,
  text,
  onContinueShoppingClicked,
  thankYouButtonText,
  image,
}) => {
  return (
    <EmailCaptureContainer image={image}>
      <Flex>
        <Flex flexDirection="column">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {heading}
          </Heading>
          <Text as="p" mb={showContinueShopping ? 6 : 0}>
            {text}
          </Text>
        </Flex>
        <HeadingImage
          src={{
            default: image.urlSmallDefault,
            webp: image.urlSmallPrimary,
          }}
          alt={image.description}
          aria-hidden
          loading="lazy"
          height={'105px'}
        />
      </Flex>
      {showContinueShopping && (
        <PrimaryButton
          onClick={onContinueShoppingClicked}
          width={{ xs: '100%', md: 'auto' }}
          mb={6}
        >
          {thankYouButtonText}
        </PrimaryButton>
      )}
    </EmailCaptureContainer>
  )
}
