import { Box, SimpleCarousel, Container } from '@moonpig/launchpad-components'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import React, { FC, useEffect } from 'react'
import {
  AddedToBasketEvent,
  createViewItemListGAEvent,
  ProductTile,
  useFavouritesState,
  useHandleAddToBasket,
  useHandleFavourite,
} from '@moonpig/web-shared-products'
import { useInView } from 'react-intersection-observer'
import { ModuleHeader } from '../ModuleContainer'
import { GetUpcomingOccasions_me_customer_recommendations_products as RecommendedProducts } from '../../queries/types-graphql'
import { useSetUIHCookie } from '../../utils'
import { useFindLocaleText } from '../../text-localisation'

type RecommendationsCarouselProps = {
  title?: string
  subtitle?: string
  products: RecommendedProducts[]
  trackingLabel?: string
  trackingDataProps: {
    pageLocation: string
  }
}

export const RecommendationsCarousel: FC<RecommendationsCarouselProps> = ({
  title,
  subtitle,
  products,
  trackingDataProps,
}) => {
  const { favourites } = useFavouritesState()
  const { setUIHCookie } = useSetUIHCookie()
  const { pageLocation } = trackingDataProps

  const [recommendationsCarouselRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const localiseText = useFindLocaleText()
  const soldOutMessage = localiseText('find.sold_out')
  const itsNotYouMessage = localiseText('find.its_not_you')
  const { handleAddToBasket } = useHandleAddToBasket({
    pageLocation,
    soldOutMessage,
    itsNotYouMessage,
  })

  const addToBasketCallback = React.useCallback(
    async (e: AddedToBasketEvent, itemIndex: number) => {
      const {
        product,
        variant,
        quantity,
        buttonActionName,
        requiresEditingValue,
      } = e

      setUIHCookie({ product })

      await handleAddToBasket({
        quantity,
        product,
        variant,
        itemIndex,
        productsLength: products.length,
        buttonActionName,
        requiresEditingValue,
        componentName: 'recommendations carousel',
      })
    },
    [setUIHCookie, handleAddToBasket, products.length],
  )

  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: products.length,
      pageLocation,
    },
    removeWithConfirmation: false,
  })

  useEffect(() => {
    if (inView) {
      trackGAEvent(
        createViewItemListGAEvent({
          products,
          label: `${pageLocation} | ${title} | carousel`,
        }),
      )
    }
  }, [inView, products, title, pageLocation])

  return (
    <div
      ref={recommendationsCarouselRef}
      data-testid="web-browse-recommendations-list"
    >
      <Container padding={{ xs: 0, md: 8 }} limitWidth>
        <ModuleHeader
          title={title}
          subtitle={subtitle}
          id="recommendations-carousel-header"
        />
        <SimpleCarousel
          gap={0}
          aria-labelledby="recommendations-carousel-header"
        >
          {products.map((product, index) => {
            const trackingData = {
              pageType: pageLocation,
              tile: {
                totalNumberOfProducts: products.length,
                productIndex: index,
              },
            }
            return (
              <Box
                key={product.id}
                py={{ xs: 4, md: 5 }}
                pr={{
                  xs: index === products.length - 1 ? 6 : 4,
                  md: 5,
                }}
                pl={{ xs: index === 0 ? 6 : 4, md: 5 }}
                height="100%"
              >
                <Box
                  width={{
                    xs: '36vw',
                    md: '248px',
                  }}
                  height="100%"
                  boxShadow="0"
                >
                  <ProductTile
                    product={product}
                    onClick={() => {}}
                    onAddToBasket={e => addToBasketCallback(e, index)}
                    trackingData={trackingData}
                    handleFavourite={handleFavourite}
                    isFavourited={Boolean(
                      favourites.find(
                        /* istanbul ignore next */ p => p?.id === product.id,
                      ),
                    )}
                  />
                </Box>
              </Box>
            )
          })}
        </SimpleCarousel>
      </Container>
    </div>
  )
}
