import React, { FC, useCallback, useEffect } from 'react'
import { Box, SimpleCarousel } from '@moonpig/launchpad-components'
import { trackGAEvent, useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { useInView } from 'react-intersection-observer'
import {
  createProductItemGAEvent,
  createViewItemListGAEvent,
  ProductItemEventEnum,
  CardProductTile,
} from '@moonpig/web-shared-products'
import {
  ModuleRecentlyViewed,
  RecentlyViewedProductEnriched,
} from '../../types'
import { useFindLocaleText } from '../../text-localisation'
import { useRecentlyViewedProducts } from '../../utils/useRecentlyViewedProducts'
import { ModuleHeader, ModuleSection } from '../../components/ModuleContainer'

type HomepageRecentlyViewedProductsProps = ModuleRecentlyViewed & {
  backgroundColor?: string
}

export const HomepageRecentlyViewedModule: FC<
  HomepageRecentlyViewedProductsProps
> = ({ trackingDataProps, backgroundColor }) => {
  const recentlyViewedProducts = useRecentlyViewedProducts()
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const localiseText = useFindLocaleText()
  const [productListRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      const { pageLocation } = trackingDataProps
      const title = localiseText('find.recently_viewed')
      const label = `${pageLocation} | ${title} | carousel`
      trackGAEventOnce(
        createViewItemListGAEvent({
          products: recentlyViewedProducts,
          label,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentlyViewedProducts, inView])

  const trackSelectItem = useCallback(
    (
      product: RecentlyViewedProductEnriched,
      itemCount: number,
      index: number,
    ) => {
      const carouselTitle = localiseText('find.recently_viewed')
      const { pageLocation } = trackingDataProps
      const listName = `${pageLocation} | ${carouselTitle} | carousel`

      const label = `${listName} | ${index + 1}/${itemCount} | ${product.id}`

      trackGAEvent(
        createProductItemGAEvent({
          eventType: ProductItemEventEnum.SELECT_ITEM,
          product,
          index,
          label,
          listName,
          variant: product.masterVariant.title,
        }),
      )
    },
    [localiseText, trackingDataProps],
  )

  return recentlyViewedProducts.length > 0 ? (
    <ModuleSection
      backgroundColor={backgroundColor}
      padding={{ py: { xs: 6, lg: 8 } }}
      data-testid="module-recently-viewed"
      withContainer
      fullWidthOnMobile
    >
      <ModuleHeader
        title={localiseText('find.recently_viewed')}
        id="recently-viewed-carousel-heading"
      />
      <div ref={productListRef} data-testid="web-browse-product-list-component">
        <SimpleCarousel
          gap={0}
          aria-labelledby="recently-viewed-carousel-heading"
        >
          {recentlyViewedProducts.map((recentlyViewedProduct, index) => (
            <Box
              key={recentlyViewedProduct.id}
              py={{ xs: 4, md: 5 }}
              pr={{
                xs: index === recentlyViewedProducts.length - 1 ? 6 : 4,
                md: 5,
              }}
              pl={{ xs: index === 0 ? 6 : 4, md: 5 }}
              height="100%"
            >
              <Box
                width={{
                  xs: '36vw',
                  md: '248px',
                }}
                height="100%"
                display="flex"
              >
                <CardProductTile
                  href={recentlyViewedProduct.url}
                  image={{
                    src: recentlyViewedProduct.image,
                    alt: recentlyViewedProduct.title,
                  }}
                  onClick={() => {
                    trackSelectItem(
                      recentlyViewedProduct,
                      recentlyViewedProducts.length,
                      index,
                    )
                  }}
                />
              </Box>
            </Box>
          ))}
        </SimpleCarousel>
      </div>
    </ModuleSection>
  ) : null
}
