import React, { FC, useState } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import {
  styled,
  breakpointUp,
  breakpointDown,
  screenReaderOnly,
} from '@moonpig/launchpad-utils'
import {
  Alert,
  Box,
  Flex,
  Heading,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { Form } from '@moonpig/web-shared-components'
import { TextInput } from '@moonpig/launchpad-forms'
import { EventObject, TrackedAnchor } from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { BUTTON_MIN_WIDTH } from './constants'
import { EmailCaptureContainer, HeadingImage } from './Shared'
import { ModuleEmailCaptureFragment } from '../__generated__/fragment'
import { EmailCaptureError } from '../store/actions'
import { useFindLocaleText } from '../../../text-localisation'

const FORM_MAX_WIDTH = 550

const StyledTextInput = styled(TextInput)<{ withError: boolean }>`
  flex-grow: 1;

  ${breakpointDown('lg')} {
    ${s({
      mb: 5,
    })}
  }

  input + div {
    ${screenReaderOnly}
    ${s({
      color: 'colorTextHeadline',
    })}
  }
  input:-webkit-autofill {
    background-color: white !important;
    box-shadow: 0 0 0px 1000px white inset !important;
  }
`

const StyledFlex = styled(Flex)`
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 8px;

  ${breakpointUp('lg')} {
    flex-wrap: wrap;
    width: 100%;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: ${BUTTON_MIN_WIDTH}px;
  order: 1;

  ${s({
    ml: {
      lg: 0,
    },
    mb: {
      xs: 10,
      lg: 0,
    },
  })}
`

const StyledAlert = styled(Alert)`
  flex-grow: 1;

  ${s({
    mb: 5,
  })}
`

const StyledTrackedAnchor = styled(TrackedAnchor)`
  ${s({
    display: {
      xs: 'block',
      lg: 'inline',
    },
    color: 'colorTextHeadline',
  })}
  font-weight: 300;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

type Props = ModuleEmailCaptureFragment & {
  onSubmit: (email: string) => void
  error?: EmailCaptureError
  privacyNoticeEventData: EventObject
}

export const EmailForm: FC<Props> = ({
  heading,
  text,
  onSubmit,
  error,
  privacyNoticeEventData,
  actionButtonText,
  image,
  inputLabel,
  inputPlaceholder,
  unsubscribeText,
  privacyPolicy,
  errorText: moduleErrorText,
}) => {
  const storeId = useStoreId()
  const [email, setEmail] = useState('')

  const t = useFindLocaleText()
  let errorText

  switch (error) {
    case EmailCaptureError.INVALID_EMAIL_MESSAGE:
      errorText = t('find.email_capture.invalid_email_address')
      break
    case EmailCaptureError.GENERIC_ERROR_MESSAGE:
      errorText = moduleErrorText
      break
  }

  return (
    <EmailCaptureContainer image={image}>
      <Box maxWidth={{ md: FORM_MAX_WIDTH }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {heading}
          </Heading>
          <HeadingImage
            src={{
              default: image.urlSmallDefault,
              webp: image.urlSmallPrimary,
            }}
            alt={image.description}
            loading="lazy"
            aria-hidden
            height={'75px'}
          />
        </Flex>
        <Text as="p" mb={5}>
          {text}
        </Text>
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit(email)
          }}
          method="post"
        >
          <StyledFlex>
            <StyledTextInput
              label={inputLabel}
              name="email"
              type="email"
              autoCorrect="off"
              spellCheck="false"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={inputPlaceholder || ''}
              withError={!!error}
            />
            <StyledPrimaryButton type="submit">
              {actionButtonText}
            </StyledPrimaryButton>
            {error && <StyledAlert variant="error">{errorText}</StyledAlert>}
          </StyledFlex>
        </Form>
        <Text as="p" mb={0}>
          {unsubscribeText}
          &nbsp;
          <StyledTrackedAnchor
            target="_blank"
            to={`/${storeId}${privacyPolicy?.url}`}
            eventData={privacyNoticeEventData}
          >
            {privacyPolicy?.title}
          </StyledTrackedAnchor>
        </Text>
      </Box>
    </EmailCaptureContainer>
  )
}
