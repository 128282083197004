import { ImpressionEvent } from '@moonpig/web-core-analytics'

export const createPromotionImpressionGAEvent = (
  uiComponent: string,
  title: string,
  experimentVariantName: string,
): ImpressionEvent => {
  const moduleType =
    uiComponent === 'ModuleDynamicHero' ? 'dynamic hero module' : uiComponent

  return {
    event: 'impression',
    content_data: {
      content_type:
        `promotion | view promotion | ${moduleType} | ${title}`.toLocaleLowerCase(),
      item_id: experimentVariantName.toLocaleLowerCase(),
    },
    // For GA3 backward compatibility
    event_data: {
      category: 'promotion',
      action: 'view promotion',
      label:
        `${moduleType} | ${experimentVariantName} | ${title}`.toLocaleLowerCase(),
      non_interaction: true,
      value: undefined,
    },
  }
}
