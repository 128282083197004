import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import {
  trackSelectContent,
  trackAddToCart,
} from '@moonpig/web-shared-memberships'

export const trackAddMembershipToCart = (
  basketId: string,
  basketSize: number,
) => {
  const brand = BRAND_LABEL.toLowerCase()
  // eslint-disable-next-line no-warning-comments
  // todo: pull price info from backend
  const price =
    brand === 'greetz'
      ? /* istanbul ignore next */ {
          centAmount: 1199,
          currencyCode: 'EUR',
        }
      : {
          centAmount: 999,
          currencyCode: 'GBP',
        }

  trackAddToCart({
    price,
    brand,
    basketId,
    basketSize,
  })
}

export const trackStartShopping = () => {
  trackSelectContent('start shopping')
}
