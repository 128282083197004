import { gql } from '@moonpig/web-core-graphql'

export const ModuleEmailCaptureFragment = gql`
  fragment ModuleEmailCapture on ModuleEmailCapture {
    heading
    text
    thankYouHeading
    thankYouText
    display
    actionButtonText
    errorText
    image {
      urlMediumPrimary: url(width: 540, quality: 80, format: webp)
      urlMediumDefault: url(width: 540, quality: 80, format: png)
      urlSmallPrimary: url(height: 150, quality: 80, format: webp)
      urlSmallDefault: url(height: 150, quality: 80, format: png)
      description
    }
    inputLabel
    inputPlaceholder
    privacyPolicy {
      title
      url
    }
    thankYouButtonText
    unsubscribeText
  }
`
